import * as yup from "yup";
import { Form, Formik } from "formik";
import { type Podcast } from "../../types/Podcast";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";
import { ApiService } from "../../services/ApiService";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useFetchPublishers } from "../../hooks/useFetchPublishers";

type PodcastRequestData = Omit<
  Podcast,
  "media" | "slug" | "createdAt" | "updatedAt" | "publisher"
> & {
  file: File | undefined;
  publisher: string;
};

const ValidationSchema = yup.object().shape({
  _id: yup.string().label("Id").optional(),
  name: yup.string().label("Nombre").required("El nombre es requerido"),
  description: yup.string().label("Descripción").optional(),
  file: yup.mixed().when(["_id"], {
    is: (_id: string) => _id === "",
    then: (schema) => schema.required("El archivo es requerido"),
    otherwise: (schema) => schema.notRequired(),
  }),
  publisher: yup.string().label("Autor").required("El campo es requerido"),
});

export const Manage = () => {
  const {
    state: { user },
    dispatch,
  } = useAdminDashboardContext();
  const { state }: { state: Podcast } = useLocation();
  const navigate = useNavigate();

  const users = useFetchPublishers();

  const podcast = useMemo<PodcastRequestData>(() => {
    if (state) {
      return {
        _id: state._id,
        name: state.name,
        description: state.description ?? "",
        publisher: state.publisher._id,
        file: undefined,
      };
    }

    return {
      _id: "",
      name: "",
      description: "",
      publisher: user?._id ?? "",
      file: undefined,
    };
  }, [state, user?._id]);

  return (
    <Formik<PodcastRequestData>
      initialValues={podcast}
      onSubmit={async (values, actions) => {
        if (!user?.token) return;
        dispatch({ type: "loading", loading: true });

        try {
          const { _id, ...data } = values;

          const formData = new FormData();
          formData.append("name", data.name);
          formData.append("publisher", data.publisher);

          if (data.description) {
            formData.append("description", data.description);
          }

          if (data.file) {
            formData.append("file", data.file as File);
          }

          if (_id === "") {
            await ApiService.podcasts(user.token).create(formData);
            dispatch({ type: "loading", loading: false });
            navigate("/podcasts");
            return;
          }

          await ApiService.podcasts(user.token).update(_id, formData);
        } catch (error: any) {
          console.error(`An error has occurred [${error.message}]`);
        } finally {
          actions.setSubmitting(false);
          dispatch({ type: "loading", loading: false });
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({
        errors,
        touched,
        values,
        isSubmitting,
        setFieldValue,
        handleChange,
      }) => (
        <Form>
          <FormField>
            <Label htmlFor="name">Nombre</Label>
            <Input name="name" type="text" placeholder="Nombre" />
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="description">Descripción</Label>
            <Input name="description" type="text" placeholder="Descripción" />
            {errors.description && touched.description ? (
              <Error>{errors.description}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="publisher">Autor</Label>
            <Input
              name="publisher"
              as="select"
              value={values.publisher || user?._id}
              onChange={handleChange}
              disabled={users.length === 1}
            >
              <option value="">Seleccione un autor</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </Input>
            {errors.publisher && touched.publisher ? (
              <Error>{errors.publisher}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="file">
              Archivo{" "}
              {state?.media?.name ? <small>({state.media.name})</small> : ""}
            </Label>
            <Input
              name="file"
              as="input"
              type="file"
              onChange={(e) => {
                if (!e.currentTarget.files) return;

                setFieldValue("file", e.currentTarget.files[0]);
              }}
            ></Input>
            {errors.file && touched.file ? <Error>{errors.file}</Error> : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/podcasts");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
