import { PropsWithChildren } from "react";
import { Actions } from "../types/Permissions";
import { useRolePemissions } from "../hooks/useRolePermissions";

type Props = {
  actions: Array<keyof typeof Actions>;
  ownerId?: string;
};
export const ProtectedAction = ({
  children,
  actions,
  ownerId,
}: PropsWithChildren<Props>) => {
  const { areActionsAllowed } = useRolePemissions();

  return <>{areActionsAllowed(actions, ownerId) ? children : null}</>;
};
