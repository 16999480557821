import { Outlet } from "react-router-dom";
import {
  PostsDetail,
  PostsHome,
  PostsManage,
  PostsReactions,
  PostsComments,
} from "../sections";
import { PageTitleWrapper, Link } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";

export const Posts = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Publicaciones</h2>
        <ProtectedAction actions={["createPost"]}>
          <Link to="/posts/create">Crear publicación</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const postsRoutes = [
  {
    path: "/posts",
    element: <PostsHome />,
  },
  {
    path: "/posts/:id",
    element: <PostsDetail />,
  },
  {
    path: "/posts/create",
    element: <PostsManage />,
  },
  {
    path: "/posts/:id/update",
    element: <PostsManage />,
  },
  {
    path: "/posts/:id/reactions",
    element: <PostsReactions />,
  },
  {
    path: "/posts/:id/comments",
    element: <PostsComments />,
  },
];
