import { useLocation } from "react-router-dom";
import { Post } from "../../types/Posts";
import { Link, PageTitleWrapper } from "../../components/Elements";
import { ProtectedAction } from "../../components/ProtectedAction";

export const Detail = () => {
  const { state }: { state: Post } = useLocation();
  return (
    <div>
      <PageTitleWrapper>
        <h3>{state.name}</h3>
        <div style={{ display: "flex", gap: "16px" }}>
          {state.enable_comments && (
            <Link to={`/posts/${state._id}/comments`} state={state}>
              Ver comentarios
            </Link>
          )}
          {state.enable_likes && (
            <Link to={`/posts/${state._id}/reactions`} state={state}>
              Ver reacciones
            </Link>
          )}
          <ProtectedAction
            actions={["editEvent", "editMyEvent"]}
            ownerId={state.publisher._id}
          >
            <Link to={`/posts/${state._id}/update`} state={state}>
              Editar articulo
            </Link>
          </ProtectedAction>
        </div>
      </PageTitleWrapper>

      <div
        style={{
          marginBottom: "1.2rem",
          display: "flex",
          gap: "16px",
          borderBottom: "1px solid #ccc",
          width: "100%",
          paddingBottom: "1.2rem",
          color: "#808080",
        }}
      >
        <span>
          Fecha de publicación{" "}
          <strong>
            {new Date(state.createdAt).toLocaleDateString("es-CR")}
          </strong>
        </span>

        <span>
          Última actualización{" "}
          <strong>
            {new Date(state.updatedAt).toLocaleDateString("es-CR")}
          </strong>
        </span>

        <span>
          Publicado por <strong>{state.publisher.name}</strong>
        </span>

        <span>
          Categoría <strong>{state.category.name}</strong>
        </span>
      </div>

      <div style={{ whiteSpace: "break-spaces" }}>{state.content}</div>

      {/* {state.reactions.length === 0 ? null : (
        <>
          <h3>Empleados que han reaccionado a la publicación</h3>
          <Table>
            <thead>
              <tr>
                <th>Empleado</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {state.reactions.map((reaction) => (
                <tr key={reaction.id}>
                  <td>{reaction.employee_name}</td>
                  <td>
                    {new Date(reaction.created_at).toLocaleString("es-CR")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {state.comments.length === 0 ? null : (
        <>
          <h3>Empleados que han comentado a la publicación</h3>
          <Table>
            <thead>
              <tr>
                <th>Empleado</th>
                <th>Fecha</th>
                <th>Comentario</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {state.comments.map((comment) => (
                <tr key={comment.id}>
                  <td>{comment.employee_name}</td>
                  <td>
                    {new Date(comment.created_at).toLocaleString("es-CR")}
                  </td>
                  <td>{comment.comment}</td>
                  <td>Borrar</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )} */}
    </div>
  );
};
