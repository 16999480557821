import styled from "styled-components";
import { Link as RouteLink } from "react-router-dom";

export const Link = styled(RouteLink)`
  text-decoration: none;
  color: #56666b;

  &:hover {
    color: #00294C;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 1rem;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
`;

export const ButtonAsLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #56666b;
  padding: 0;
  margin: 0;

  &:hover {
    color: #00294C;
  }
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.2rem;
  gap: 16px
`;
