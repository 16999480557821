import { Outlet } from "react-router-dom";
import { UsersHome, UsersManage } from "../sections";
import { Link, PageTitleWrapper } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";

export const Users = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Usuarios</h2>
        <ProtectedAction actions={["createUser"]}>
          <Link to="/users/create">Crear usuario</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const usersRoutes = [
  {
    path: "/users",
    element: <UsersHome />,
  },
  {
    path: "/users/create",
    element: <UsersManage />,
  },
  {
    path: "/users/:id/update",
    element: <UsersManage />,
  },
];
