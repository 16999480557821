const getUrl = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || "production";

  switch (env) {
    case "production":
      return "https://fifco.api.informados.lat";
    case "staging":
      return "https://staging.fifco.api.informados.lat";
    case "development":
    default:
      return "http://localhost:3001";
  }
};

const API_HEADERS = {
  Accept: "application/json",
  "x-source": "dashboard",
};

const request = async <D extends object>(
  method: "GET" | "POST" | "DELETE" | "PUT",
  uri: string,
  token: string | null,
  data?: D
) => {
  let body = undefined;

  const headers: Record<string, string> = {
    ...API_HEADERS,
  };

  if (typeof data !== "undefined") {
    if (!(data instanceof FormData)) {
      body = JSON.stringify(data);
      headers["Content-Type"] = "application/json";
    } else {
      body = data;
    }
  }

  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(`${getUrl()}/${uri}`, {
      method,
      headers,
      body,
    });

    if (response.status === 401) {
      localStorage.removeItem("user");
      throw new Error(response.statusText, { cause: response.status });
    }

    if (response.status >= 300) {
      throw new Error(response.statusText, { cause: response.status });
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getRequest = (uri: string, token: string) => request("GET", uri, token);

const postRequest = <D extends object>(
  uri: string,
  token: string | null,
  data: D
) => request<D>("POST", uri, token, data);

const putRequest = <D extends object>(
  uri: string,
  token: string | null,
  data: D
) => request<D>("PUT", uri, token, data);

const deleteRequest = (uri: string, token: string) =>
  request("DELETE", uri, token);

export { getRequest, postRequest, putRequest, deleteRequest };
