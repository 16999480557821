import { Outlet } from "react-router-dom";
import { Link, PageTitleWrapper } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";
import { CategoriesHome, CategoriesManage } from "../sections";

export const Categories = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Categorías</h2>
        <ProtectedAction actions={["createCategory"]}>
          <Link to="/categories/create">Crear categoría</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const categoriesRoutes = [
  {
    path: "/categories",
    element: <CategoriesHome />,
  },
  {
    path: "/categories/create",
    element: <CategoriesManage />,
  },
  {
    path: "/categories/:id/update",
    element: <CategoriesManage />,
  },
];
