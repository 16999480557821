import { Field } from "formik";
import styled from "styled-components";

export const FormField = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

export const Error = styled.div`
  color: red;
  padding: 0.25rem 0;
`;

export const Input = styled(Field)`
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #FFF;
`;

const BaseButton = styled.button`
  padding: 0.5rem;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: #0071CE;
  border: 1px solid #00A0DF;
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: #434F57;
  border: 1px solid #000;
`;

export const FormFooter = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
