import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import Dashboard from "./Dashboard";
import { Login } from "./Login";
import { AdminDashboardProvider } from "./context/AdminContext";
import {
  Posts,
  postsRoutes,
  Categories,
  categoriesRoutes,
  Events,
  eventsRoutes,
  Users,
  usersRoutes,
  Notifications,
  notificationsRoutes,
  Podcasts,
  podcastsRoutes,
  Radios,
  radiosRoutes,
} from "./pages";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    > div {
      height: 100vh;
    }
  }

  a, button {
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
  }
`;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/posts",
        element: <Posts />,
        children: postsRoutes,
      },
      {
        path: "/categories",
        element: <Categories />,
        children: categoriesRoutes,
      },
      {
        path: "/podcasts",
        element: <Podcasts />,
        children: podcastsRoutes,
      },
      {
        path: "/radios",
        element: <Radios />,
        children: radiosRoutes,
      },
      {
        path: "/events",
        element: <Events />,
        children: eventsRoutes,
      },
      {
        path: "/notifications",
        element: <Notifications />,
        children: notificationsRoutes,
      },
      {
        path: "/users",
        element: <Users />,
        children: usersRoutes,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("dashboard") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <AdminDashboardProvider>
      <RouterProvider router={router} />
    </AdminDashboardProvider>
  </React.StrictMode>
);
