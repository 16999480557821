import * as yup from "yup";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Event } from "../../types/Event";
import { useMemo } from "react";
import { Form, Formik } from "formik";
import { ApiService } from "../../services/ApiService";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";

const ValidationSchema = yup.object().shape({
  id: yup.number().label("ID").notRequired().default(-1),
  title: yup.string().label("Titulo").required("El titulo es requerido"),
  description: yup
    .string()
    .label("Descripción")
    .required("La descripción es requerida"),
  date: yup.string().label("Fecha").required("La fecha es requerida"),
});

export const Manage = () => {
  const {
    state: { user },
    dispatch,
  } = useAdminDashboardContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const event = useMemo<Event>(() => {
    if (state) {
      return state;
    }

    return {
      id: -1,
      title: "",
      description: "",
      date: "",
    };
  }, [state]);

  return (
    <Formik
      initialValues={event}
      onSubmit={async (values, actions) => {
        if (!user?.token) return;
        dispatch({ type: "loading", loading: true });
        const { id, ...data } = values;

        try {
          if (id === null) {
            await ApiService.events(user.token).create(data);
            actions.setSubmitting(false);
            navigate("/calendar");
            return;
          }

          await ApiService.events(user.token).update(id, data);
          actions.setSubmitting(false);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: "loading", loading: false });
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormField>
            <Label htmlFor="title">Titulo</Label>
            <Input name="title" type="text" placeholder="Titulo" />
            {errors.title && touched.title ? (
              <Error>{errors.title}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="date">Fecha</Label>
            <Input name="date" type="date" placeholder="Fecha" />
            {errors.date && touched.date ? <Error>{errors.date}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="description">Descripción</Label>
            <Input name="description">
              {({ field }: any) => <Input as="textarea" rows={6} {...field} />}
            </Input>
            {errors.description && touched.description ? (
              <Error>{errors.description}</Error>
            ) : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/calendar");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
