import { useCallback, useEffect } from "react";
import { ApiService } from "../../services/ApiService";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";
import { type Post } from "../../types/Posts";
import { ProtectedAction } from "../../components/ProtectedAction";

export const Home = () => {
  const {
    state: { user, posts },
    dispatch,
  } = useAdminDashboardContext();

  const fetchPosts = useCallback(async () => {
    if (!user?.token) return;
    dispatch({ type: "loading", loading: true });

    const result = await ApiService.posts(user.token).findAll();

    dispatch({ type: "set-posts", posts: result });
    dispatch({ type: "loading", loading: false });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts, user?.token]);

  const allowedActions = useCallback((post: Post): string => {
    const actions = [];

    if (post.enable_comments) {
      actions.push("Comentar");
    }

    if (post.enable_likes) {
      actions.push("Reaccionar");
    }

    return actions.join(" - ");
  }, []);

  return (
    <>
      {posts.length === 0 ? (
        <p>No hay articulos</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Categoría</th>
              <th>Autor</th>
              <th>Empleado puede</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => (
              <tr key={post._id}>
                <td>{post.name}</td>
                <td>{post.category.name}</td>
                <td>{post.publisher.name}</td>
                <td>{allowedActions(post)}</td>
                <td>
                  <Menu>
                    <li>
                      <Link to={`/posts/${post._id}`} state={post}>
                        Ver
                      </Link>
                    </li>
                    <ProtectedAction
                      actions={["editEvent", "editMyEvent"]}
                      ownerId={post.publisher._id}
                    >
                      <li>
                        <Link to={`/posts/${post._id}/update`} state={post}>
                          Editar
                        </Link>
                      </li>
                    </ProtectedAction>
                    <ProtectedAction
                      actions={["deletePost", "deleteMyPost"]}
                      ownerId={post.publisher._id}
                    >
                      <li>
                        <ButtonAsLink
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Está seguro de que desea eliminarlo?"
                              ) &&
                              user?.token
                            ) {
                              ApiService.posts(user.token)
                                .delete(post._id)
                                .then(() => {
                                  const newPosts = posts.filter(
                                    (a) => a._id !== post._id
                                  );
                                  dispatch({
                                    type: "set-posts",
                                    posts: newPosts,
                                  });
                                });
                            }
                          }}
                        >
                          Eliminar
                        </ButtonAsLink>
                      </li>
                    </ProtectedAction>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
