import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu } from "../components/Elements";
import { useCallback } from "react";
import { useAdminDashboardContext } from "../context/AdminContext";

const Wrapper = styled.div`
  background-color: #00294C;
  color: #ffffff;
  padding: 1rem;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuItem = styled.li`
  cursor: pointer;
`;

const Link = styled(NavLink)`
  color: #fff;
  text-decoration: none;

  &:hover,
  &.active {
    color: #FF8300;
  }
`;

const Button = styled.a`
  border: none;
  background: transparent;
  color: #fff;
  text-decoration: none;

  &:hover,
  &.active {
    color: #FF8300;
  }
`;

export const Header = () => {
  const {
    dispatch,
    state: { user },
  } = useAdminDashboardContext();
  const navigate = useNavigate();

  const closeSession = useCallback(() => {
    dispatch({ type: "logout" });
    navigate("/login");
  }, [dispatch, navigate]);

  return (
    <Wrapper>
      <Container>
        <div>
          <div style={{ fontWeight: 'bold' }}>FIFCO Radio</div>
          <div>
            <small>Hola {user?.name} usted es un {user?.role}</small>
          </div>
        </div>

        <Menu>
          <MenuItem>
            <Link to="/">Inicio</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/posts">Publicaciones</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/categories">Categorías</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/podcasts">Podcasts</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/radios">Radios</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/events">Eventos</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/users">Usuarios</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/notifications">Notificaciones</Link>
          </MenuItem>
          <MenuItem>
            <Button onClick={() => closeSession()}>Salir</Button>
          </MenuItem>
        </Menu>
      </Container>
    </Wrapper>
  );
};
