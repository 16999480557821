import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";

export const Home = () => {
  const {
    state: { user, events },
    dispatch,
  } = useAdminDashboardContext();

  const fetchEvents = useCallback(async () => {
    if (!user?.token) return;

    const result = await ApiService.events(user.token).findAll();

    dispatch({ type: "set-events", events: result });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, user?.token]);

  return (
    <>
      {events.length === 0 ? (
        <p>No hay eventos</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Descripción</th>
              <th>Fecha</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.title}</td>
                <td>
                  {event.description.length > 50
                    ? event.description.substring(0, 50) + "..."
                    : event.description}
                </td>
                <td>{new Date(event.date).toLocaleDateString("es-CR")}</td>
                <td>
                  <Menu>
                    <li>
                      <Link to={`/calendar/${event.id}`} state={event}>
                        Ver
                      </Link>
                    </li>
                    <li>
                      <Link to={`/calendar/${event.id}/update`} state={event}>
                        Editar
                      </Link>
                    </li>
                    <li>
                      <ButtonAsLink
                        onClick={() => {
                          if (
                            window.confirm(
                              "¿Está seguro de que desea eliminarlo?"
                            ) &&
                            user?.token
                          ) {
                            ApiService.events(user.token)
                              .delete(event.id)
                              .then(() => {
                                const newEvents = events.filter(
                                  (a) => a.id !== event.id
                                );
                                dispatch({
                                  type: "set-events",
                                  events: newEvents,
                                });
                              });
                          }
                        }}
                      >
                        Eliminar
                      </ButtonAsLink>
                    </li>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
