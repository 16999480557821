import { Formik, Form } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import { ApiService } from "./services/ApiService";
import { useAdminDashboardContext } from "./context/AdminContext";
import { User } from "./types/User";
import { useNavigate } from "react-router-dom";
import {
  FormField,
  Input,
  Label,
  Error,
  PrimaryButton,
} from "./components/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Box = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 3rem;
`;

interface LoginAttributes {
  username: string;
  password: string;
}

const LoginValidationSchema = yup.object().shape({
  username: yup.string().label("Username").required(),
  password: yup.string().label("Password").required(),
});

export const Login = () => {
  const { dispatch } = useAdminDashboardContext();
  const navigate = useNavigate();
  const initialValues: LoginAttributes = { username: "", password: "" };

  return (
    <Wrapper>
      <Box>
        <h1>Login</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            const login = await ApiService.auth().login(
              values.username,
              values.password
            );

            const user = jwtDecode<User>(login.access_token);
            const authenticatedUser = { ...user, token: login.access_token };

            localStorage.setItem("user", JSON.stringify(authenticatedUser));

            dispatch({
              type: "login",
              user: authenticatedUser,
            });
            navigate("/");

            actions.setSubmitting(false);
          }}
          validationSchema={LoginValidationSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormField>
                <Label htmlFor="username">Nombre de usuario</Label>
                <Input
                  name="username"
                  type="username"
                  placeholder="Nombre de usuario"
                />
                {errors.username && touched.username ? (
                  <Error>{errors.username}</Error>
                ) : null}
              </FormField>
              <FormField>
                <Label htmlFor="password">Password</Label>
                <Input name="password" type="password" />
                {errors.password && touched.password ? (
                  <Error>{errors.password}</Error>
                ) : null}
              </FormField>

              <PrimaryButton type="submit" disabled={isSubmitting}>
                Ingresar
              </PrimaryButton>
            </Form>
          )}
        </Formik>
      </Box>
    </Wrapper>
  );
};
