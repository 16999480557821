import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useMemo } from "react";
import { User } from "../../types/User";
import { Form, Formik } from "formik";
import { ApiService } from "../../services/ApiService";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";

const ValidationSchema = yup.object().shape({
  sub: yup.number().label("ID").notRequired().default(null),
  name: yup.string().label("Nombre").required("El nombre es requerido"),
  username: yup
    .string()
    .label("Nombre de usuario")
    .required("El nombre de usuario es requerido"),
  password: yup
    .string()
    .label("Contraseña")
    .when("sub", {
      is: (sub: string) => sub === null,
      then: (schema) =>
        schema
          .required("La contraseña es requerida")
          .min(8, "La contraseña debe tener al menos 8 caracteres"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const Manage = () => {
  const {
    state: { user: authenticatedUser },
    dispatch,
  } = useAdminDashboardContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const manager = useMemo<User>(() => {
    if (state) {
      return {
        ...state,
        password: "",
      };
    }

    return {
      _id: null,
      username: "",
      name: "",
      password: "",
      role: "viewer",
    };
  }, [state]);

  return (
    <Formik
      initialValues={manager}
      onSubmit={async (values, actions) => {
        if (!authenticatedUser?.token) return;
        dispatch({ type: "loading", loading: true });
        const { _id, ...data } = values;

        try {
          if (_id === null) {
            await ApiService.users(authenticatedUser.token).create(data);
            actions.setSubmitting(false);
            navigate("/managers");
            return;
          }

          await ApiService.users(authenticatedUser.token).update(_id, data);
          dispatch({ type: "loading", loading: false });
        } catch (e) {
          console.error(e);
        } finally {
          dispatch({ type: "loading", loading: false });
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormField>
            <Label htmlFor="name">Nombre</Label>
            <Input name="name" type="text" placeholder="Nombre" />
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="username">Nombre de usuario</Label>
            <Input
              name="username"
              type="username"
              placeholder="Nombre de usuario"
            />
            {errors.username && touched.username ? (
              <Error>{errors.username}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="password">Contraseña</Label>
            <Input name="password" type="password" placeholder="Contraseña" />
            {errors.password && touched.password ? (
              <Error>{errors.password}</Error>
            ) : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              type="reset"
              onClick={(e) => {
                e.preventDefault();
                navigate("/managers");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
