import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useMemo } from "react";
import { Form, Formik } from "formik";
import { ApiService } from "../../services/ApiService";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";
import { type Category } from "../../types/Category";

const ValidationSchema = yup.object().shape({
  name: yup.string().label("Nombre").required("El nombre es requerido"),
  description: yup.string().label("Descripción"),
});

export const Manage = () => {
  const {
    state: { user: authenticatedUser },
    dispatch,
  } = useAdminDashboardContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const category = useMemo<Category>(() => {
    if (state) {
      return state;
    }

    return {
      _id: null,
      name: "",
      description: "",
    };
  }, [state]);

  return (
    <Formik
      initialValues={category}
      onSubmit={async (values, actions) => {
        if (!authenticatedUser?.token) return;
        dispatch({ type: "loading", loading: true });
        const { _id, ...data } = values;

        try {
          if (_id === null) {
            await ApiService.categories(authenticatedUser.token).create(data);
            actions.setSubmitting(false);
            navigate("/categories");
            return;
          }

          await ApiService.categories(authenticatedUser.token).update(
            _id,
            data
          );
          dispatch({ type: "loading", loading: false });
        } catch (e) {
          console.error(e);
        } finally {
          dispatch({ type: "loading", loading: false });
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormField>
            <Label htmlFor="name">Nombre</Label>
            <Input name="name" type="text" placeholder="Nombre" />
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="description">Descripción</Label>
            <Input name="description" type="text" placeholder="Descripción" />
            {errors.description && touched.description ? (
              <Error>{errors.description}</Error>
            ) : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              type="reset"
              onClick={(e) => {
                e.preventDefault();
                navigate("/categories");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
