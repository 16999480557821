import { type Category } from "../types/Category";
import { type Comment } from "../types/Comment";
import { type Post } from "../types/Posts";
import { type Reaction } from "../types/Reaction";
import { getRequest, postRequest, putRequest, deleteRequest } from "./request";

export const ApiService = {
  auth: () => ({
    login: (username: string, password: string) =>
      postRequest<Record<string, string>>("auth/login", null, {
        username,
        password,
      }),
  }),
  posts: <D extends object>(token: string) => ({
    findAll: (): Promise<Array<Post>> => getRequest("posts", token),
    create: (data: D): Promise<Post> => postRequest<D>("posts", token, data),
    update: (id: string, data: D): Promise<Post> =>
      putRequest<D>(`posts/${id}`, token, data),
    delete: (id: string): Promise<void> => deleteRequest(`posts/${id}`, token),
  }),
  postReactions: (token: string) => ({
    findAllByPost: (postId: string): Promise<Array<Reaction>> =>
      getRequest(`posts-reactions/${postId}`, token),
  }),
  postComments: (token: string) => ({
    findAllByPost: (postId: string): Promise<Array<Comment>> =>
      getRequest(`posts-comments/${postId}`, token),
  }),
  categories: <D extends object>(token: string) => ({
    findAll: (): Promise<Array<Category>> => getRequest("categories", token),
    update: (id: string, data: D) =>
      putRequest<D>(`categories/${id}`, token, data),
    delete: (id: string) => deleteRequest(`categories/${id}`, token),
    create: (data: D) => postRequest<D>("categories", token, data),
  }),
  podcasts: <D extends object>(token: string) => ({
    findAll: () => getRequest("podcasts", token),
    update: (id: string, data: D) =>
      putRequest<D>(`podcasts/${id}`, token, data),
    delete: (id: string) => deleteRequest(`podcasts/${id}`, token),
    create: (data: D) => postRequest<D>("podcasts", token, data),
  }),
  radios: <D extends object>(token: string) => ({
    findAll: () => getRequest("radios", token),
    create: (data: D) => postRequest<D>("radios", token, data),
    update: (id: string, data: D) => putRequest<D>(`radios/${id}`, token, data),
    delete: (id: string) => deleteRequest(`radios/${id}`, token),
  }),
  events: <D extends object>(token: string) => ({
    findAll: () => getRequest("events", token),
    create: (data: D) => postRequest<D>("events", token, data),
    update: (id: string, data: D) => putRequest<D>(`events/${id}`, token, data),
    delete: (id: string) => deleteRequest(`events/${id}`, token),
  }),
  users: <D extends object>(token: string) => ({
    findAll: () => getRequest("users", token),
    create: (data: D) => postRequest<D>("users", token, data),
    update: (id: string, data: D) => putRequest<D>(`users/${id}`, token, data),
    delete: (id: string) => deleteRequest(`users/${id}`, token),
  }),
  notifications: <D extends object>(token: string) => ({
    findAll: () => getRequest("notifications", token),
    create: (data: D) => postRequest<D>("notifications", token, data),
  }),
};
