export enum Roles {
  viewer = "viewer",
  publisher = "publisher",
  admin = "admin",
}

export enum Actions {
  viewPost,
  createPost,
  editPost,
  editMyPost,
  deletePost,
  deleteMyPost,

  viewRadio,
  createRadio,
  editRadio,
  editMyRadio,
  deleteRadio,
  deleteMyRadio,

  viewPodcast,
  createPodcast,
  editPodcast,
  editMyPodcast,
  deletePodcast,
  deleteMyPodcast,

  viewEvent,
  createEvent,
  editEvent,
  editMyEvent,
  deleteEvent,
  deleteMyEvent,

  viewUser,
  createUser,
  editUser,
  deleteUser,

  viewNotification,
  createNotification,
  editNotification,
  editMyNotification,
  deleteNotification,
  deleteMyNotification,

  viewCategory,
  createCategory,
  editCategory,
  deleteCategory,
}
