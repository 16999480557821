import styled from "styled-components";

export const Table = styled.table`
  border-bottom: solid 0.5px #ccc;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border-top: solid 0.5px #ccc;
    border-right: solid 0.5px #ccc;
    padding: 0.8rem;

    &:first-of-type {
      border-left: solid 0.5px #ccc;
    }
  }
`;