import * as yup from "yup";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "../../types/Radio";
import { useMemo } from "react";
import { Formik, Form } from "formik";
import { ApiService } from "../../services/ApiService";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";

type RadioRequest = Omit<Radio, "createdAt" | "updatedAt">;

const ValidationSchema = yup.object().shape({
  name: yup.string().label("Name").required("El nombre es requerido"),
  url: yup.string().label("URL").url().required("La URL es requerida"),
});

export const Manage = () => {
  const {
    state: { user },
    dispatch,
  } = useAdminDashboardContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const radio = useMemo<RadioRequest>(() => {
    if (state) {
      return state;
    }

    return {
      _id: null,
      name: "",
      url: "",
    };
  }, [state]);

  return (
    <Formik<RadioRequest>
      initialValues={radio}
      onSubmit={async (values, actions) => {
        if (!user?.token) return;
        dispatch({ type: "loading", loading: true });

        try {
          const { _id, ...data } = values;

          if (_id === null) {
            await ApiService.radios(user.token).create(data);
            actions.setSubmitting(false);
            dispatch({ type: "loading", loading: false });
            actions.setSubmitting(false);
            navigate("/radios");
            return;
          }

          await ApiService.radios(user.token).update(_id, data);
        } catch (error: any) {
          console.error(`An error has occurred [${error.message}]`);
        } finally {
          dispatch({ type: "loading", loading: false });
          actions.setSubmitting(false);
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormField>
            <Label htmlFor="name">Nombre</Label>
            <Input name="name" type="text" placeholder="Titulo" />
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="url">URL</Label>
            <Input name="url" type="url" placeholder="URL" />
            {errors.url && touched.url ? <Error>{errors.url}</Error> : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/radios");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
