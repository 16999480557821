import { useLocation } from "react-router-dom";
import { type Post } from "../../types/Posts";
import { useCallback, useEffect, useState } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { Link, PageTitleWrapper } from "../../components/Elements";
import { type Comment } from "../../types/Comment";

export const Comments = () => {
  const { state }: { state: Post } = useLocation();
  const {
    state: { user, loading },
    dispatch,
  } = useAdminDashboardContext();

  const [postComments, setPostComments] = useState<Comment[]>([]);

  const fetchPostReactions = useCallback(
    async (token: string, postId: string) => {
      dispatch({ type: "loading", loading: true });

      try {
        const result = await ApiService.postComments(token).findAllByPost(
          postId
        );
        setPostComments(result);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "loading", loading: false });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user?.token) return;

    fetchPostReactions(user.token, state._id);
  }, [fetchPostReactions, state._id, user?.token]);

  return (
    <>
      <PageTitleWrapper>
        <h3>Reactions for {state.name}</h3>

        <Link to={`/posts/${state._id}`} state={state}>
          Volver
        </Link>
      </PageTitleWrapper>
      {!loading && postComments.length > 0 ? (
        <Table>
          <thead>
            <th>Empleado</th>
            <th>Comentario</th>
            <th>Fecha</th>
          </thead>
          <tbody>
            {postComments.map((comment) => (
              <tr key={comment._id}>
                <td>{comment.employee_name}</td>
                <td>{comment.comment}</td>
                <td>
                  {new Date(comment.createdAt).toLocaleDateString("es-CR")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        "No hay comentarios"
      )}
    </>
  );
};
