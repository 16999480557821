import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";
import { ProtectedAction } from "../../components/ProtectedAction";

export const Home = () => {
  const {
    state: { user, radios },
    dispatch,
  } = useAdminDashboardContext();

  const fetchRadios = useCallback(async () => {
    if (!user?.token) return;

    const result = await ApiService.radios(user.token).findAll();

    dispatch({ type: "set-radios", radios: result });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchRadios();
  }, [fetchRadios, user?.token]);

  return (
    <>
      {radios.length === 0 ? (
        <p>No hay radios</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Radio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {radios.map((radio) => (
              <tr key={radio._id}>
                <td>{radio.name}</td>
                <td>
                  <audio controls>
                    <source src={radio.url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td>
                  <Menu>
                    <ProtectedAction actions={["editRadio"]}>
                      <li>
                        <Link to={`/radios/${radio._id}/update`} state={radio}>
                          Editar
                        </Link>
                      </li>
                    </ProtectedAction>

                    <ProtectedAction actions={["deleteRadio"]}>
                      <li>
                        <ButtonAsLink
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Está seguro de que desea eliminarlo?"
                              ) &&
                              user?.token
                            ) {
                              ApiService.radios(user.token)
                                .delete(radio._id)
                                .then(() => {
                                  const newRadios = radios.filter(
                                    (a) => a._id !== radio._id
                                  );
                                  dispatch({
                                    type: "set-radios",
                                    radios: newRadios,
                                  });
                                });
                            }
                          }}
                        >
                          Eliminar
                        </ButtonAsLink>
                      </li>
                    </ProtectedAction>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
