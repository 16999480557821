import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";

export const Home = () => {
  const {
    state: { user, notifications },
    dispatch,
  } = useAdminDashboardContext();

  const fetchNotifications = useCallback(async () => {
    if (!user?.token) return;
    dispatch({ type: "loading", loading: true });

    const result = await ApiService.notifications(user.token).findAll();

    dispatch({ type: "set-notifications", notifications: result });
    dispatch({ type: "loading", loading: false });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, user?.token]);

  return (
    <>
      {notifications.length === 0 ? (
        <p>No hay notificaciones</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Mensaje</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification) => (
              <tr key={notification.id}>
                <td>{notification.title}</td>
                <td>{notification.message}</td>
                <td>
                  {new Date(notification.date).toLocaleDateString("es-CR")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
