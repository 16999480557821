import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";
import { ProtectedAction } from "../../components/ProtectedAction";

export const Home = () => {
  const {
    state: { user, podcasts },
    dispatch,
  } = useAdminDashboardContext();

  const fetchPodcasts = useCallback(async () => {
    if (!user?.token) return;
    dispatch({ type: "loading", loading: true });
    const result = await ApiService.podcasts(user.token).findAll();

    dispatch({ type: "set-podcasts", podcasts: result });
    dispatch({ type: "loading", loading: false });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchPodcasts();
  }, [fetchPodcasts, user?.token]);

  return (
    <>
      {podcasts.length === 0 ? (
        <p>No hay podcasts</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Autor</th>
              <th>Audio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {podcasts.map((podcast) => (
              <tr key={podcast._id}>
                <td>{podcast.name}</td>
                <td>{podcast.publisher?.name}</td>
                <td>
                  <audio controls>
                    <source src={podcast.media.url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td>
                  <Menu>
                    <ProtectedAction
                      actions={["editPodcast", "editMyPodcast"]}
                      ownerId={podcast.publisher._id}
                    >
                      <li>
                        <Link
                          to={`/podcasts/${podcast._id}/update`}
                          state={podcast}
                        >
                          Editar
                        </Link>
                      </li>
                    </ProtectedAction>

                    <ProtectedAction
                      actions={["deletePodcast", "deleteMyPodcast"]}
                      ownerId={podcast.publisher._id}
                    >
                      <li>
                        <ButtonAsLink
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Está seguro de que desea eliminarlo?"
                              ) &&
                              user?.token
                            ) {
                              ApiService.podcasts(user.token)
                                .delete(podcast._id)
                                .then(() => {
                                  const newPodcasts = podcasts.filter(
                                    (a) => a._id !== podcast._id
                                  );
                                  dispatch({
                                    type: "set-podcasts",
                                    podcasts: newPodcasts,
                                  });
                                });
                            }
                          }}
                        >
                          Eliminar
                        </ButtonAsLink>
                      </li>
                    </ProtectedAction>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
