import React, { useEffect } from "react";
import { useAdminDashboardContext } from "./context/AdminContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header } from "./layout/Header";
import styled from "styled-components";
import { Spinner } from "./components/Spinner";

const Wrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
`;

function Dashboard() {
  const {
    dispatch,
    state: { loading },
  } = useAdminDashboardContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    if (user?.token) {
      dispatch({ type: "login", user });
      return;
    }

    navigate("/login", { state: { from: location }, replace: true });
  }, [dispatch, location, navigate]);

  return (
    <>
      <Header />
      <Wrapper>
        <div style={{ padding: "1rem" }}>
          <Outlet />
        </div>
      </Wrapper>
      {loading && <Spinner />}
    </>
  );
}

export default Dashboard;
