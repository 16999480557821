import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  FormField,
  Input,
  Label,
  Error,
  FormFooter,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Form";
import { useNavigate } from "react-router-dom";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";

const ValidationSchema = yup.object().shape({
  title: yup.string().label("Titulo").required("El titulo es requerido"),
  message: yup.string().label("Mensaje").required("El mensaje es requerido"),
});

export const Manage = () => {
  const {
    state: { user },
    dispatch,
  } = useAdminDashboardContext();

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{ title: "", message: "" }}
      onSubmit={async (values, actions) => {
        if (!user?.token) return;
        dispatch({ type: "loading", loading: true });

        await ApiService.notifications(user.token).create(values);
        actions.setSubmitting(false);
        dispatch({ type: "loading", loading: false });
        actions.resetForm();
        alert("Notificación enviada");
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormField>
            <Label htmlFor="title">Titulo</Label>
            <Input name="title" type="text" placeholder="Titulo" />
            {errors.title && touched.title ? (
              <Error>{errors.title}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="message">Mensaje</Label>
            <Input name="message" type="text" placeholder="Mensaje" />
            {errors.message && touched.message ? (
              <Error>{errors.message}</Error>
            ) : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Enviar
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/notifications");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
