import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";
import { ProtectedAction } from "../../components/ProtectedAction";
import { useFetchCategories } from "../../hooks/useFetchCategories";

export const Home = () => {
  const {
    state: { user: authenticatedUser },
    dispatch,
  } = useAdminDashboardContext();

  const categories = useFetchCategories();

  return (
    <>
      {categories.length === 0 ? (
        <p>No hay categorías</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Fecha</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>{category.name}</td>
                <td>{category.description || "-"}</td>
                <td>
                  {new Date(category.createdAt).toLocaleDateString("es-CR")}
                </td>
                <td>
                  <Menu>
                    <ProtectedAction actions={["editCategory"]}>
                      <li>
                        <Link
                          to={`/categories/${category._id}/update`}
                          state={category}
                        >
                          Editar
                        </Link>
                      </li>
                    </ProtectedAction>
                    <ProtectedAction actions={["deleteCategory"]}>
                      <li>
                        <ButtonAsLink
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Está seguro de que desea eliminarlo?"
                              ) &&
                              authenticatedUser?.token
                            ) {
                              ApiService.categories(authenticatedUser.token)
                                .delete(category._id)
                                .then(() => {
                                  const newCategories = categories.filter(
                                    (a) => a._id !== category._id
                                  );
                                  dispatch({
                                    type: "set-categories",
                                    categories: newCategories,
                                  });
                                });
                            }
                          }}
                        >
                          Eliminar
                        </ButtonAsLink>
                      </li>
                    </ProtectedAction>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
