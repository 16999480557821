import { useMemo } from "react";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Post } from "../../types/Posts";
import {
  FormField,
  Input,
  Label,
  PrimaryButton,
  Error,
  SecondaryButton,
  FormFooter,
} from "../../components/Form";
import { ApiService } from "../../services/ApiService";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { useFetchPublishers } from "../../hooks/useFetchPublishers";
import { useFetchCategories } from "../../hooks/useFetchCategories";

type PostRequestData = Omit<
  Post,
  "slug" | "createdAt" | "updatedAt" | "publisher" | "category"
> & {
  category: string;
  publisher: string;
};

const ValidationSchema = yup.object().shape({
  name: yup.string().label("Nombre").required("El nombre es requerido"),
  content: yup
    .string()
    .label("Contenido")
    .required("El contenido es requerido"),
  enable_comments: yup
    .boolean()
    .label("Habilitar comentarios")
    .required("El campo es requerido"),
  enable_likes: yup
    .boolean()
    .label("Habilitar reacciones")
    .required("El campo es requerido"),
  category: yup.string().label("Categoría").required("El campo es requerido"),
  publisher: yup.string().label("Autor").required("El campo es requerido"),
});

export const Manage = () => {
  const {
    state: { user },
    dispatch,
  } = useAdminDashboardContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const users = useFetchPublishers();
  const categories = useFetchCategories();

  const post = useMemo<PostRequestData>(() => {
    if (state !== null) {
      return {
        ...state,
        category: state.category._id,
        publisher: state.publisher._id,
      };
    }

    return {
      _id: null,
      name: "",
      content: "",
      enable_comments: true,
      enable_likes: true,
      category: "",
      publisher: user?._id ?? "",
    };
  }, [state, user?._id]);

  return (
    <Formik<PostRequestData>
      initialValues={{ ...post }}
      onSubmit={async (values, actions) => {
        if (!user?.token) return;
        dispatch({ type: "loading", loading: true });

        if (values._id === null) {
          await ApiService.posts<PostRequestData>(user.token).create(values);
          actions.setSubmitting(false);
          dispatch({ type: "loading", loading: false });
          actions.setSubmitting(false);
          navigate("/posts");
          return;
        }

        await ApiService.posts<PostRequestData>(user.token).update(
          values._id,
          values
        );
        dispatch({ type: "loading", loading: false });
        actions.setSubmitting(false);
        return;
      }}
      validationSchema={ValidationSchema}
    >
      {({ errors, touched, values, isSubmitting, handleChange }) => (
        <Form>
          <FormField>
            <Label htmlFor="name">Titulo</Label>
            <Input name="name" type="text" placeholder="Titulo" />
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
          </FormField>

          <FormField>
            <Label htmlFor="category">Categoría</Label>
            <Input
              name="category"
              as="select"
              value={values.category}
              onChange={handleChange}
            >
              <option value="">Seleccione una categoría</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Input>
            {errors.category && touched.category ? (
              <Error>{errors.category}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="publisher">Autor</Label>
            <Input
              name="publisher"
              as="select"
              value={values.publisher || user?._id}
              onChange={handleChange}
              disabled={users.length === 1}
            >
              <option value="">Seleccione un autor</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </Input>
            {errors.publisher && touched.publisher ? (
              <Error>{errors.publisher}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="enable_comments">
              <Input type="checkbox" name="enable_comments" />
              Permitir comentarios
            </Label>
            {errors.enable_comments && touched.enable_comments ? (
              <Error>{errors.enable_comments}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="enable_likes">
              <Input type="checkbox" name="enable_likes" />
              Permitir reacciones
            </Label>
            {errors.enable_likes && touched.enable_likes ? (
              <Error>{errors.enable_likes}</Error>
            ) : null}
          </FormField>

          <FormField>
            <Label htmlFor="content">Contenido</Label>
            <Input name="content">
              {({ field }: any) => <Input as="textarea" rows={24} {...field} />}
            </Input>
            {errors.content && touched.content ? (
              <Error>{errors.content}</Error>
            ) : null}
          </FormField>

          <FormFooter>
            <PrimaryButton type="submit" disabled={isSubmitting}>
              Guardar
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/posts");
              }}
            >
              Cancelar
            </SecondaryButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};
