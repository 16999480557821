import { useLocation } from "react-router-dom";
import { type Post } from "../../types/Posts";
import { useCallback, useEffect, useState } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { type Reaction } from "../../types/Reaction";
import { Link, PageTitleWrapper } from "../../components/Elements";

export const Reactions = () => {
  const { state }: { state: Post } = useLocation();
  const {
    state: { user, loading },
    dispatch,
  } = useAdminDashboardContext();

  const [postReactions, setPostReactions] = useState<Reaction[]>([]);

  const fetchPostReactions = useCallback(
    async (token: string, postId: string) => {
      dispatch({ type: "loading", loading: true });

      try {
        const result = await ApiService.postReactions(token).findAllByPost(
          postId
        );
        setPostReactions(result);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: "loading", loading: false });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user?.token) return;

    fetchPostReactions(user.token, state._id);
  }, [fetchPostReactions, state._id, user?.token]);

  return (
    <>
      <PageTitleWrapper>
        <h3>Reactions for {state.name}</h3>

        <Link to={`/posts/${state._id}`} state={state}>
          Volver
        </Link>
      </PageTitleWrapper>
      {!loading && postReactions.length > 0 ? (
        <Table>
          <thead>
            <th>Empleado</th>
            <th>Reacción</th>
            <th>Fecha</th>
          </thead>
          <tbody>
            {postReactions.map((reaction) => (
              <tr key={reaction._id}>
                <td>{reaction.employee_name}</td>
                <td>{reaction.reaction_type}</td>
                <td>
                  {new Date(reaction.createdAt).toLocaleDateString("es-CR")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        "No hay reacciones"
      )}
    </>
  );
};
