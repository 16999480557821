import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../context/AdminContext";
import { ApiService } from "../services/ApiService";
import { Roles } from "../types/Permissions";

export const useFetchPublishers = () => {
  const {
    state: { user, users },
    dispatch,
  } = useAdminDashboardContext();

  const fetchPublishers = useCallback(async () => {
    if (!user?.token || users.length > 0) return;

    const result = await ApiService.users(user.token).findAll();

    dispatch({ type: "set-users", users: result });
  }, [users.length, dispatch, user?.token]);

  const setCurrentUserAsPublisher = useCallback(() => {
    if (!user) return;
    dispatch({ type: "set-users", users: [user] });
  }, [dispatch, user]);

  useEffect(() => {
    if (user?.role !== Roles.admin) {
      setCurrentUserAsPublisher();
      return;
    }

    fetchPublishers();
  }, [fetchPublishers, setCurrentUserAsPublisher, user?.role]);

  return users;
};
