import { useLocation } from "react-router-dom";
import { Event } from "../../types/Event";
import { Link, PageTitleWrapper } from "../../components/Elements";

export const Detail = () => {
  const { state }: { state: Event } = useLocation();
  return (
    <div>
      <PageTitleWrapper>
        <h3>{state.title}</h3>
        <Link to={`/calendar/${state.id}/update`} state={state}>
          Editar evento
        </Link>
      </PageTitleWrapper>

      <div style={{ marginBottom: "1rem" }}>
        <p>
          Fecha del evento{" "}
          <strong>{new Date(state.date).toLocaleDateString("es-CR")}</strong>
        </p>
      </div>

      <div style={{ whiteSpace: "break-spaces" }}>{state.description}</div>
    </div>
  );
};
