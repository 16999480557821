import { Outlet } from "react-router-dom";
import { EventsDetail, EventsHome, EventsManage } from "../sections";
import { Link, PageTitleWrapper } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";

export const Events = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Eventos</h2>
        <ProtectedAction actions={["createEvent"]}>
          <Link to="/events/create">Crear evento</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const eventsRoutes = [
  {
    path: "/events",
    element: <EventsHome />,
  },
  {
    path: "/events/:id",
    element: <EventsDetail />,
  },
  {
    path: "/events/create",
    element: <EventsManage />,
  },
  {
    path: "/events/:id/update",
    element: <EventsManage />,
  },
];
