import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../context/AdminContext";
import { ApiService } from "../services/ApiService";

export const useFetchCategories = () => {
  const {
    state: { user, categories },
    dispatch,
  } = useAdminDashboardContext();

  const fetchCategories = useCallback(async () => {
    if (!user?.token) return;

    const result = await ApiService.categories(user.token).findAll();

    dispatch({ type: "set-categories", categories: result });
  }, [dispatch, user?.token]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories, user?.token]);

  return categories;
};
