import { Outlet } from "react-router-dom";
import {
  NotificationsDetail,
  NotificationsHome,
  NotificationsManage,
} from "../sections";
import { Link, PageTitleWrapper } from "../components/Elements";

export const Notifications = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Notificationes</h2>
        <Link to="/notifications/create">Enviar notificación</Link>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const notificationsRoutes = [
  {
    path: "/notifications",
    element: <NotificationsHome />,
  },
  {
    path: "/notifications/:id",
    element: <NotificationsDetail />,
  },
  {
    path: "/notifications/create",
    element: <NotificationsManage />,
  },
  {
    path: "/notifications/:id/update",
    element: <NotificationsManage />,
  },
];
