import { useCallback, useEffect } from "react";
import { useAdminDashboardContext } from "../../context/AdminContext";
import { ApiService } from "../../services/ApiService";
import { Table } from "../../components/Table";
import { ButtonAsLink, Link, Menu } from "../../components/Elements";
import { ProtectedAction } from "../../components/ProtectedAction";

export const Home = () => {
  const {
    state: { user: authenticatedUser, users },
    dispatch,
  } = useAdminDashboardContext();

  const fetchManagers = useCallback(async () => {
    if (!authenticatedUser?.token) return;

    const result = await ApiService.users(authenticatedUser.token).findAll();

    dispatch({ type: "set-users", users: result });
  }, [dispatch, authenticatedUser?.token]);

  useEffect(() => {
    fetchManagers();
  }, [fetchManagers, authenticatedUser?.token]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Nombre de usuario</th>
            <th>Rol</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.username}</td>
              <td style={{ textAlign: "center" }}>{user.role}</td>
              <td>
                <Menu>
                  <ProtectedAction actions={["editUser"]}>
                    <li>
                      <Link to={`/users/${user._id}/update`} state={user}>
                        Editar
                      </Link>
                    </li>
                  </ProtectedAction>
                  <ProtectedAction actions={["deleteUser"]}>
                    <li>
                      <ButtonAsLink
                        onClick={() => {
                          if (
                            window.confirm(
                              "¿Está seguro de que desea eliminarlo?"
                            ) &&
                            authenticatedUser?.token
                          ) {
                            ApiService.users(authenticatedUser.token)
                              .delete(user._id)
                              .then(() => {
                                const newUsers = users.filter(
                                  (a) => a._id !== user._id
                                );
                                dispatch({
                                  type: "set-users",
                                  users: newUsers,
                                });
                              });
                          }
                        }}
                      >
                        Eliminar
                      </ButtonAsLink>
                    </li>
                  </ProtectedAction>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
