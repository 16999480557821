import { useCallback } from "react";
import { useAdminDashboardContext } from "../context/AdminContext";
import { Actions, Roles } from "../types/Permissions";

const viewContent = [
  Actions.viewPost,
  Actions.viewEvent,
  Actions.viewNotification,
  Actions.viewPodcast,
  Actions.viewPost,
  Actions.viewRadio,
  Actions.viewUser,
  Actions.viewCategory,
];

const createContent = [
  Actions.createPost,
  Actions.createPodcast,
  Actions.createEvent,
  Actions.createCategory,
];

const manageMyContent = [
  Actions.editMyPost,
  Actions.deleteMyPost,

  Actions.editMyRadio,
  Actions.deleteMyRadio,

  Actions.editMyPodcast,
  Actions.deleteMyPodcast,

  Actions.editMyEvent,
  Actions.deleteMyEvent,

  Actions.editMyNotification,
  Actions.deleteMyNotification,
];

const manageContent = [
  Actions.editPost,
  Actions.deletePost,

  Actions.createRadio,
  Actions.editRadio,
  Actions.deleteRadio,

  Actions.editPodcast,
  Actions.deletePodcast,

  Actions.editEvent,
  Actions.deleteEvent,

  Actions.createUser,
  Actions.editUser,
  Actions.deleteUser,

  Actions.createNotification,
  Actions.editNotification,
  Actions.deleteNotification,

  Actions.editCategory,
  Actions.deleteCategory,
];

const permissionsByRole = {
  [Roles.viewer]: [...viewContent],
  [Roles.publisher]: [...viewContent, ...createContent, ...manageMyContent],
  [Roles.admin]: [...viewContent, ...createContent, ...manageContent],
};

export const useRolePemissions = (): {
  areActionsAllowed: (
    actions: Array<keyof typeof Actions>,
    ownerId?: string
  ) => boolean;
  contentBelongsToMe: (ownerId: string) => boolean;
} => {
  const {
    state: { user },
  } = useAdminDashboardContext();

  const contentBelongsToMe = useCallback(
    (ownerId: string): boolean => {
      if (!user) return false;

      return user._id === ownerId;
    },
    [user]
  );

  const areActionsAllowed = useCallback(
    (actions: Array<keyof typeof Actions>, ownerId?: string): boolean => {
      if (!user) return false;

      const rolePemissions = permissionsByRole[user.role];

      const actionIsAllowed = actions.some((action) => {
        const actionToCheck = Actions[action];

        return rolePemissions.includes(actionToCheck);
      });
      if (user.role === Roles.publisher && typeof ownerId !== "undefined") {
        return actionIsAllowed && contentBelongsToMe(ownerId);
      }

      return actionIsAllowed;
    },
    [contentBelongsToMe, user]
  );

  return { areActionsAllowed, contentBelongsToMe };
};
