import { Outlet } from "react-router-dom";
import { PodcastsHome, PodcastsManage } from "../sections";
import { Link, PageTitleWrapper } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";

export const Podcasts = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Podcasts</h2>
        <ProtectedAction actions={["createPodcast"]}>
          <Link to="/podcasts/create">Crear podcast</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const podcastsRoutes = [
  {
    path: "/podcasts",
    element: <PodcastsHome />,
  },
  {
    path: "/podcasts/create",
    element: <PodcastsManage />,
  },
  {
    path: "/podcasts/:id/update",
    element: <PodcastsManage />,
  },
];
