import { Outlet } from "react-router-dom";
import { RadiosHome, RadiosManage } from "../sections";
import { Link, PageTitleWrapper } from "../components/Elements";
import { ProtectedAction } from "../components/ProtectedAction";

export const Radios = () => {
  return (
    <>
      <PageTitleWrapper>
        <h2>Radios</h2>
        <ProtectedAction actions={["createRadio"]}>
          <Link to="/radios/create">Crear radio</Link>
        </ProtectedAction>
      </PageTitleWrapper>
      <Outlet />
    </>
  );
};

export const radiosRoutes = [
  {
    path: "/radios",
    element: <RadiosHome />,
  },
  {
    path: "/radios/create",
    element: <RadiosManage />,
  },
  {
    path: "/radios/:id/update",
    element: <RadiosManage />,
  },
];
