import React from "react";
import { type Post } from "../types/Posts";
import { type Radio } from "../types/Radio";
import { type Podcast } from "../types/Podcast";
import { type User } from "../types/User";
import { type Event } from "../types/Event";
import { type Notification } from "../types/Notification";
import { type Category } from "../types/Category";

type AuthenticatedUser = User & { token: string };

type State = {
  isAuthenticated: boolean;
  loading: boolean;
  user: AuthenticatedUser | undefined;
  posts: Post[];
  radios: Radio[];
  podcasts: Podcast[];
  events: Event[];
  users: User[];
  notifications: Notification[];
  categories: Category[];
};

const initialState: State = {
  isAuthenticated: false,
  loading: false,
  user: undefined,
  posts: [],
  radios: [],
  podcasts: [],
  events: [],
  users: [],
  notifications: [],
  categories: [],
};

type Action =
  | { type: "login"; user: AuthenticatedUser }
  | { type: "logout" }
  | { type: "loading"; loading: boolean }
  | { type: "set-posts"; posts: Post[] }
  | { type: "set-radios"; radios: Radio[] }
  | { type: "set-podcasts"; podcasts: Podcast[] }
  | { type: "set-events"; events: Event[] }
  | { type: "set-notifications"; notifications: Notification[] }
  | { type: "set-users"; users: User[] }
  | { type: "set-categories"; categories: Category[] };
type Dispatch = (action: Action) => void;

type AdminDashboardProviderProps = { children: React.ReactNode };

const AdminDashboardContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const adminDashboardReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "login": {
      return { ...state, isAuthenticated: true, user: action.user };
    }
    case "logout": {
      return { ...state, isAuthenticated: false, user: undefined };
    }
    case "loading": {
      return { ...state, loading: action.loading };
    }
    case "set-posts": {
      return { ...state, posts: action.posts };
    }
    case "set-radios": {
      return { ...state, radios: action.radios };
    }
    case "set-podcasts": {
      return { ...state, podcasts: action.podcasts };
    }
    case "set-events": {
      return { ...state, events: action.events };
    }
    case "set-users": {
      return { ...state, users: action.users };
    }
    case "set-notifications": {
      return { ...state, notifications: action.notifications };
    }
    case "set-categories": {
      return { ...state, categories: action.categories };
    }
    default: {
      return state;
    }
  }
};

const AdminDashboardProvider = ({ children }: AdminDashboardProviderProps) => {
  const [state, dispatch] = React.useReducer(
    adminDashboardReducer,
    initialState
  );
  const value = { state, dispatch };
  return (
    <AdminDashboardContext.Provider value={value}>
      {children}
    </AdminDashboardContext.Provider>
  );
};

const useAdminDashboardContext = () => {
  const context = React.useContext(AdminDashboardContext);
  if (context === undefined) {
    throw new Error(
      "Admin Dashboard Context must be used within the Admin Dashboard Provider"
    );
  }
  return context;
};

export { AdminDashboardProvider, useAdminDashboardContext };
